<template>
  <v-responsive>
    <SubMenuExpertsMobile v-if="$vuetify.breakpoint.smAndDown"></SubMenuExpertsMobile>
    <v-container :fluid="$vuetify.breakpoint.lgAndDown" style="max-width:1440px;"> 


      <v-row>
        <v-col cols="12" sm="3" flat tile v-if="$vuetify.breakpoint.mdAndUp">
          <v-container class="pa-0">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0 pl-6" flat tile>
                <h2
                  class="
                    bx_pagetitle
                    float-left
                    mt-5
                    mb-3
                    blue--text
                    text--darken-4
                  "
                >
               
                  {{ breadcrumbs[1].text }}
                </h2>
              </v-card>
            </v-card>
          </v-container>
          <v-divider></v-divider>
          <SubMenuExperts></SubMenuExperts>
        </v-col>




        <v-col cols="12" sm="12" md="9" :class="$vuetify.breakpoint.smAndDown? 'mt-0 pt-0':''">
          <v-container class="pa-0" v-if="$vuetify.breakpoint.mdAndUp">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0" flat tile>
                <h2 class="bx_pagetitle float-left mt-5 mb-3">
                  {{ breadcrumbs[2].text }}
                   
                </h2>
              </v-card>
              <v-card class="pa-0" flat tile v-if="$vuetify.breakpoint.mdAndUp">
                <v-breadcrumbs :items="breadcrumbs" class="float-right mt-2">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-card>
            </v-card>
          <v-divider></v-divider>
          </v-container>

          <v-sheet>
            <!--  -->

            <SocialList
              count="10"
              class="mt-5"
              v-if="!id"
              :category="category"
            ></SocialList>

            <SocialView
              v-if="id && mode === 'view'"
              :id="id"
              :from="from"
            ></SocialView>

            <SocialWrite
              v-if="id === 'new' || (id && mode === 'edit')"
              :id="id"
              :mode="mode"
            ></SocialWrite>

            <v-row
              justify="space-between"
              no-gutters
              class="mt-5 pa-0"
              v-if="mode === 'list'"
            >
              <v-col align="left" justify="center"> </v-col>



              <v-col align="right" class="align-end" v-if="$store.state.isAuthenticated">
                <v-btn
                  v-if="category"
                  color="grey lighten-3 "
                  depressed
                  @click="goUrl(`/experts/${category}/new`)"
                >
                  글쓰기
                </v-btn>

                <v-btn
                  v-else
                  color="grey lighten-3 "
                  depressed
                  @click="goUrl(`/experts/a/new`)"
                >
                  글쓰기
                </v-btn>
              </v-col>


              <v-col align="right" class="align-end" v-if="!$store.state.isAuthenticated">
                <v-btn
                  v-if="category"
                  color="grey lighten-3 "
                  depressed
                  @click="goUrl(`/login/?redirect=${$route.path}`)"
                >
                  글쓰기
                </v-btn>
              </v-col>
              


            </v-row>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-responsive>
</template>

<script>
import SocialList from "../components/SocialList.vue";
//import HelloWorld from "../components/HelloWorld";

export default {
  name: "Mypage",

  components: {
    SocialList,
    //HelloWorld,
  },
  data: () => ({
    id: null,
    category: null,
    mode: null,

    breadcrumbs: [
      { text: "홈" },
      { text: "로또 명인" },
      { text: "전문가 분석" },
      //{ text: "당첨 통계", icon: "mdi-menu-right" },
    ],
    cruds: [
      ["행운의여신 전문가", "mdi-menu-right"],
      ["유비천하 전문가", "mdi-menu-right"],
      ["천우운풍 전문가", "mdi-menu-right"],
      ["신의경지 전문가", "mdi-menu-right"],
      ["골드넘버 전문가", "mdi-menu-right"],
    ],

    from: null,
  }),

  watch: {
    $route(to, from) {
      if (to.path != from.path) {
        /* router path가 변경될 때마다 */

        this.checkMode();
        
    this.setSubtitle();
    
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    console.log(from);
    console.log(from);
    console.log(from);
    console.log(from);
    this.from = from;
    next();
  },

  created() {
    this.checkMode();
    this.setSubtitle();
  },

  computed: {
    // 계산된 getter
    subTitle: function () {
      // `this` 는 vm 인스턴스를 가리킵니다.


      return this.category;
    },
  },
  methods: {

    setSubtitle() {
        this.breadcrumbs[2].text = '전체 보기'
      if(this.category == 'free') {
        this.breadcrumbs[2].text = '자유 토론'
      }
      if(this.category == 'challenge') {
        this.breadcrumbs[2].text = '명인 도전방'
      }
      if(this.category == 'expert') {
        this.breadcrumbs[2].text = '전문가 분석'
      }
    

    },


    checkMode() {
      this.id = this.$route.params.id;
      this.category = this.$route.params.category;
      this.mode = this.$route.params.mode;

      if (!this.id && !this.mode) {
        this.mode = "list";
      }

      if (this.id && !this.mode) {
        this.mode = "view";
      }
      if (this.id === "new") {
        this.mode = "write";
      }

      if (this.id && this.mode === "edit") {
        this.mode = "edit";
      }
    },
  },
};
</script>
<style></style>
